<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      title="批量修改"
      ok-text="下一步"
      @ok="handleValidate"
    >
      <a-row :gutter="24">
        <a-form
          class="iotplt-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-form-item
            label="修改字段"
            :label-col="{ span: 2 }"
            :wrapper-col="{ span: 21 }"
          >
            <a-radio-group
              v-decorator="['modify_column',{ initialValue: modifyColumn }]"
            >
              <a-radio value="group_name">
                卡号分组
              </a-radio>
              <a-radio value="device_sn">
                设备序列号
              </a-radio>
              <a-radio value="device_name">
                设备名称
              </a-radio>
              <a-radio value="remark">
                备注
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item
            :wrapper-col="{ span: 24 }"
          >
            <common-iccid-input
              :rows="15"
              ref="iccidInput"
              v-decorator="['iccids', {
                initialValue: { data: this.iccids },
                rules: [{ validator: checkIccids }],
              }]"
            />
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSecondStepModal"
      centered
      :width="900"
      :mask-closable="false"
      title="批量修改"
    >
      <template slot="footer">
        <a-button @click="closeSecondStepModal">
          取消
        </a-button>
        <a-button
          class="ant-btn-primary"
          @click="showFirstStepModal"
        >
          上一步
        </a-button>
        <a-button
          class="ant-btn-primary"
          @click="handleSecondStepSubmit"
          :loading="secondStepSubmitting"
        >
          下一步
        </a-button>
      </template>

      <a-row :gutter="24">
        <a-form
          class="iotplt-compact-form"
          :form="secondStepForm"
          @submit="handleSecondStepSubmit"
        >
          <a-row>
            <a-form-item
              label="修改字段"
              :label-col="{ span: 2 }"
              :wrapper-col="{ span: 22 }"
            >
              <a-radio-group
                disabled
                v-decorator="['modify_column',{ initialValue: modifyColumn }]"
              >
                <a-radio value="group_name">
                  卡号分组
                </a-radio>
                <a-radio value="device_sn">
                  设备序列号
                </a-radio>
                <a-radio value="device_name">
                  设备名称
                </a-radio>
                <a-radio value="remark">
                  备注
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-row>

          <a-row :gutter="24">
            <a-col :span="11">
              <a-form-item>
                <a-textarea
                  :rows="15"
                  disabled
                  v-decorator="['iccids',{ initialValue: iccidInputValues }]"
                />
              </a-form-item>

              <a-form-item label="卡号总数" :style="{ display: 'inline-block', width: '50%' }">
                <span class="cards-count">{{ iccidsData.length }}</span>
              </a-form-item>

              <a-form-item label="重复数量" :style="{ display: 'inline-block', width: '50%' }">
                <span class="cards-count">{{ 0 }}</span>
              </a-form-item>
            </a-col>

            <a-col :span="13">
              <a-form-item
                :wrapper-col="{ span: 24 }"
              >
                <a-textarea
                  :placeholder="resultsPlaceholder"
                  :rows="15"
                  v-decorator="['values', {
                    rules: [
                      { validator: checkValues }
                    ],
                  }]"
                />
              </a-form-item>

              <a-form-item :label="`${modifyColumnDisplay()}数量`">
                <span>{{ values.length }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowThirdStepModal"
      centered
      :width="900"
      :mask-closable="false"
      title="批量修改"
    >
      <template slot="footer">
        <a-button @click="closeThirdStepModal">
          取消
        </a-button>
        <a-button
          class="ant-btn-primary"
          @click="showSecondStepModal"
        >
          上一步
        </a-button>
        <a-button
          class="ant-btn-primary"
          @click="handleThirdStepSubmit"
          :loading="thirdStepSubmitting"
        >
          确定
        </a-button>
      </template>

      <a-descriptions :column="1">
        <a-descriptions-item label="修改字段">
          <span> {{ modifyColumnDisplay() }} </span>
        </a-descriptions-item>

        <a-descriptions-item label="修改结果">
          最终以批量操作结果为准
        </a-descriptions-item>
      </a-descriptions>

      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :scroll="{ y: 350 }"
        row-key="iccid"
      />
    </a-modal>
  </div>
</template>

<script>
import CommonIccidInput from '@/components/IccidInput/Common'
import { validateBatchModifySimCards, batchModifySimCards } from '@/api/batch_operation'

export default {
  name: 'BatchModifySimCards',
  components: { CommonIccidInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      resultsPlaceholder: '·一行输入一项内容，每行内容将依次与左侧同行的ICCID建立对应关系\n' +
      '·设备序列号将可作为小程序查询续费的搜索项，请确保唯一性',
      modifyColumn: 'group_name',
      iccidInputValues: '',
      iccidsData: [],
      values: [],
      columns: [
        {
          title: 'ICCID',
          dataIndex: 'iccid'
        },
        {
          title: '修改内容',
          dataIndex: 'value'
        }
      ],
      tableData: [],
      isShowFirstStepModal: true,
      isShowSecondStepModal: false,
      isShowThirdStepModal: false,
      firstStepSubmitting: false,
      secondStepSubmitting: false,
      thirdStepSubmitting: false,
      firstStepForm: this.$form.createForm(this, { name: 'batch_modify_first_step' }),
      secondStepForm: this.$form.createForm(this, { name: 'batch_modify_second_step' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSecondStepModal || this.isShowThirdStepModal
    },

    isShowSecondStepModal(val) {
      this.isShow = val || this.isShowFirstStepModal || this.isShowThirdStepModal
    },

    isShowThirdStepModal(val) {
      this.isShow = val || this.isShowFirstStepModal || this.isShowSecondStepModal
    }
  },
  methods: {
    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入ICCID')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkValues(rule, value, callback) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`请输入${this.modifyColumnDisplay()}`)
        return
      }
      this.values = this.$lodash.split(value, /\r*\n/)
      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    modifyColumnDisplay() {
      switch (this.modifyColumn) {
        case 'group_name':
          return '卡号分组'
        case 'device_sn':
          return '设备序列号'
        case 'device_name':
          return '设备名称'
        case 'remark':
          return '备注'
        default:
          return ''
      }
    },

    showFirstStepModal() {
      this.isShowFirstStepModal = true
      this.closeSecondStepModal()
    },

    closeFirstStepModal() {
      this.isShowFirstStepModal = false
    },

    showSecondStepModal() {
      this.isShowSecondStepModal = true
      this.closeFirstStepModal()
      this.closeThirdStepModal()
    },

    closeSecondStepModal() {
      this.isShowSecondStepModal = false
    },

    showThirdStepModal() {
      this.isShowThirdStepModal = true
      this.isShowFirstStepModal = false
      this.isShowSecondStepModal = false
      this.tableData = []
      this.iccidsData.forEach((iccid, index) => {
        this.tableData.push({
          iccid: iccid,
          value: this.values[index]
        })
      })
    },

    closeThirdStepModal() {
      this.isShowThirdStepModal = false
    },

    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          this.iccidsData = values.iccids.data
          validateBatchModifySimCards({
            iccids: this.iccidsData,
            agent_id: this.agentId
          }).then((res) => {
            if (res.code === 0) {
              this.firstStepForm.setFieldsValue({
                iccids: { inputValue: res.data.result }
              })

              this.$nextTick(() => {
                this.$refs.iccidInput.handleIccids()
              })

              if (res.data.is_valid) {
                this.iccidInputValues = res.data.result
                this.modifyColumn = values.modify_column
                if (this.secondStepForm.getFieldError('values')) {
                  this.$nextTick(() => {
                    this.secondStepForm.validateFields(['values'], { force: true })
                  })
                }
                // 关闭模态框
                this.closeFirstStepModal()
                this.showSecondStepModal()
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSecondStepSubmit(e) {
      e.preventDefault()
      if (this.secondStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.secondStepForm.validateFields((err, values) => {
        if (!err) {
          this.secondStepSubmitting = true
          if (this.values.length !== this.firstStepForm.getFieldValue('iccids').data.length) {
            this.$warning({
              title: `卡号总数与${this.modifyColumnDisplay()}总数不一致!`,
              content: ''
            })
            this.secondStepSubmitting = false
            return
          }

          this.secondStepSubmitting = false
          this.showThirdStepModal()
        }
      })
    },

    handleThirdStepSubmit(e) {
      e.preventDefault()
      if (this.thirdStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      const data = {
        agent_id: this.agentId,
        modify_column: this.modifyColumn,
        iccids: this.iccidsData,
        values: this.values
      }

      this.thirdStepSubmitting = true
      batchModifySimCards(data).then((res) => {
        if (res.code === 0) {
          this.closeThirdStepModal()

          this.$success({
            title: '提交成功',
            content: (
              <div>
                <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
              </div>
            )
          })
        }
        this.thirdStepSubmitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cards-count {
  padding-left: 20px;
}
</style>
